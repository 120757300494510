<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M4 5.50047V14.5005M7 4.75047V15.2505M10 5.54664V14.4543C10 15.4302 10.9171 16.1462 11.8638 15.9095L14.8638 15.1595C15.5315 14.9926 16 14.3927 16 13.7043V6.29664C16 5.60834 15.5315 5.00836 14.8638 4.84143L11.8638 4.09143C10.9171 3.85474 10 4.57078 10 5.54664Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

  </svg>
</template>

<script>
export default {
  name: 'AITagIcon',
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    }
  }
}
</script>

<style scoped></style>
