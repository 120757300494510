<template>
  <!-- Advertisement Details Drawer -->
  <BaseDrawer
    no-padding
    height-auto-only
    no-bottom-rounding
    no-toolbar
    class="scrollbar-hide"
    :mobile-view="mobileView"
    @close="$emit('close'), SET_AD_PLAYING('')"
  >
    <template #content>
      <!-- Content -->
      <div
        class="w-full flex flex-col reg-details:flex-row rounded-t-3xl bg-neutral-50 reg-details:overflow-hidden p-2 reg-details:p-0"
        :style="{height: mobileView ? 'auto' : 'calc(100vh - 80px)', minHeight: mobileView ? 'calc(100vh - 80px)' : 'auto'}"
      >
        <!-- TOOLBAR & AD CARD -->
        <div
          class="flex flex-col flex-grow"
          :style="{height: mobileView ? 'auto' : 'calc(100vh - 80px)'}"
        >
          <!-- TOOLBAR -->
          <div class="relative w-full flex items-center justify-center reg-details:px-5 py-0 reg-details:py-4 mt-6">
            <!-- Previous ad button -->
            <!-- <button
              v-if="!mobileView"
              class="flex items-center gap-1.5 pl-1.5 py-1.5 pr-3 rounded-md"
              :class="previousAd ? 'transition-colors hover:bg-neutral-25' : 'cursor-default opacity-80'"
              :disabled="!previousAd"
              @click="previousAdvertisement"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                class="transition-colors"
                :class="previousAd ? 'text-icon-normal' : 'text-icon-disabled'"
              >
                <path
                  d="M11.3215 13.3327L8.57742 10.5886C8.25198 10.2632 8.25198 9.73553 8.57742 9.41009L11.3215 6.66602"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <BaseText
                type="label"
                size="sm"
                class="transition-colors"
                :class="previousAd ? 'text-text-muted' : 'text-text-disabled'"
              >
                Previous
              </BaseText>
            </button> -->
            <!-- Center toolbar actions -->
            <div
              class="actions-shadow flex items-center gap-1 p-2 bg-white min-w-0 flex-shrink mx-auto reg-details:mx-0 w-full reg-details:w-auto"
              :class="{'center-toolbar-actions': !mobileView}"
              style="border-radius: 14px"
            >
              <!-- External ad link -->
              <div
                v-if="currentAd.ad_id"
                class="flex items-center gap-1 px-1.5 rounded-md min-w-0 flex-shrink"
              >
                <BaseText
                  type="body"
                  size="sm"
                  class="text-text-normal truncate whitespace-nowrap"
                >
                  Creative Breakdown ID:
                </BaseText>
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-muted min-w-0 flex-shrink truncate"
                >
                  {{ currentAd.creative_id }}
                </BaseText>
                <a
                  v-if="currentAd.live && currentAd?.publisher_platform.some((platform) => ['facebook', 'instagram'].includes(platform)) && currentAd.ad_id"
                  :href="currentAd.organic ? getInstagramUrl : `https://www.facebook.com/ads/library/?id=${currentAd.ad_id}`"
                  target="_blank"
                  class="group p-1.5 rounded-md transition-colors hover:bg-neutral-25"
                >
                  <OpenExternalIcon class="text-icon-normal transition-colors group-hover:text-icon-muted" />
                </a>
              </div>
              <!-- Vertical line spacer -->
              <div
                v-if="currentAd.ad_id"
                class="w-px h-4 bg-neutral-50 mx-auto reg-details:mx-1"
              />
              <!-- Share button -->
              <button
                v-clipboard:copy="getShareLink"
                v-clipboard:success="handleClipboardCopy"
                class="black-btn-primary flex items-center gap-1.5 pl-2 py-1.5 pr-2.5 rounded-md"
                :class="{'enabled': !shareDisabled}"
                :disabled="shareDisabled"
              >
                <ShareIcon :class="shareDisabled ? 'text-text-disabled' : 'text-white'" />
                <BaseText
                  type="label"
                  size="sm"
                  :class="shareDisabled ? 'text-text-disabled' : 'text-white'"
                >
                  Share
                </BaseText>
              </button>
            </div>
            <!-- Next ad button -->
            <!-- <button
              v-if="!mobileView"
              class="flex items-center gap-1.5 pl-3 py-1.5 pr-1.5 rounded-md"
              :class="!nextAdDisabled ? 'transition-colors hover:bg-neutral-25' : 'cursor-default opacity-60'"
              :disabled="nextAdDisabled"
              @click="nextAdvertisement"
            >
              <BaseText
                type="label"
                size="sm"
                class="transition-colors"
                :class="!nextAdDisabled ? 'text-text-muted' : 'text-text-disabled'"
              >
                Next
              </BaseText>
              <BaseLoadingSpinnerCircle
                v-if="loadingNewAds"
                class="text-icon-normal"
              />
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                class="transition-colors"
                :class="!nextAdDisabled ? 'text-icon-normal' : 'text-icon-disabled'"
              >
                <path
                  d="M8.33331 13.3327L11.0774 10.5886C11.4028 10.2632 11.4028 9.73553 11.0774 9.41009L8.33331 6.66602"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button> -->
          </div>
          <!-- AD CARD -->
          <div
            ref="advertisement-card-area"
            class="relative w-full flex-grow min-h-0 overflow-y-scroll scrollbar-hide py-2.5 reg-details:py-6"
          >
            <div
              class="max-w-sm z-10 h-max mx-auto"
              :class="{'centered-card-wrapper': !enableAdCardScroll}"
            >
              <LibraryCard
                ref="advertisement-card"
                :advertisement="currentAd || advertisement"
                :discovery="discovery"
                :shared-board="sharedBoard"
                :shared-brief="sharedBrief"
                :init-ad-copy-expanded="withAdCopyExpanded"
                in-ad-details-drawer
                all-ad-settings
                hide-boards
                no-drawer
                hide-copy-button
                relative-media
                class="w-max max-w-sm"
                @carouselIndex="carouselIndex = $event"
                @expandCopy="determineAdCardOverflow"
                @loaded-content="determineAdCardOverflow"
              />
            </div>
          </div>
          <!-- <div
            v-if="currentAd?.cards?.length && !mobileView"
            class="absolute left-4 transform -translate-y-1/2 z-50"
            style="top: calc(50% - 26px)"
          >
         
          </div> -->
        </div>

        <!-- RIGHT-HAND INFO CARD -->
        <div
          class="info-card flex flex-col mt-1 mr-1 bg-white overflow-y-scroll scrollbar-hide bg-transparent"
          :style="{height: mobileView ? 'auto' : 'calc(100vh - 80px)', width: mobileView ? '100%' : '448px'}"
          style="border-radius: 20px"
        >
          <!-- Tab Switcher -->
          <div class="flex items-center gap-2 p-4 border-b border-border-normal justify-center reg-details:justify-start">
            <button
              v-for="navBtn in tabNavigations"
              :key="navBtn.view"
              :disabled="navBtn.isDisabled"
              class="group px-2 py-1.5 flex items-center gap-1.5 rounded-md transition-colors whitespace-nowrap"
              :class="{'hover:bg-neutral-10': sidebarView !== navBtn.view && !navBtn.isDisabled, 'cursor-not-allowed opacity-75': navBtn.isDisabled}"
              @click="sidebarView = navBtn.view"
            >
              <BaseText
                type="label"
                size="sm"
                class="transition-colors"
                :class="`${sidebarView === navBtn.view ? 'text-text-muted' : navBtn.isDisabled ? 'text-text-disabled' : 'text-text-subdued group-hover:text-text-muted'}`"
              >
                {{ navBtn.name }}
              </BaseText>
              <BaseText
                v-if="navBtn.isDisabled"
                size="xs"
                class="text-text-disabled"
              >
                (Soon)
              </BaseText>
              <div
                v-if="navBtn.isNew"
                class="px-1.5 py-0.5 rounded-full"
                :class="`bg-primary-${getTheme}-10`"
              >
                <BaseText
                  type="label"
                  size="xs"
                  :class="`text-primary-${getTheme}-300`"
                >
                  New
                </BaseText>
              </div>
            </button>
          </div>
          <!-- ======= Sidebar Views ========== -->
          <div class="py-2 px-4">
            <div
              v-if="sidebarView === SIDEVIEW.DETAILS || sidebarView === SIDEVIEW.METRICS"
              class="px-2 py-1.5 flex items-center justify-between"
            >
              <div class="flex items-center gap-1.5">
                <CalendarIcon class="text-icon-normal" />
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-muted"
                >
                  {{ dateRangeLabel.rangeName ?? 'Custom Range' }}
                </BaseText>
              </div>
              <BaseText
                size="sm"
                class="text-text-normal"
              >
                {{ dateRangeLabel.range }}
              </BaseText>
            </div>
          </div>
          <!-- DETAILS VIEW -->
          <div
            v-if="sidebarView === SIDEVIEW.DETAILS"
            class="py-2 px-4 flex flex-col gap-2.5 flex-1"
          >
            <div class="flex flex-col border-border-normal border rounded-2xl">
              <div class="flex flex-col gap-1.5 p-4 border-b border-border-normal">
                <div class="flex items-center gap-1">
                  <BaseText
                    type="label"
                    size="sm"
                    class="text-text-muted"
                  >
                    Spend
                  </BaseText>
                  <div
                    class="relative"
                  >
                    <InfoSimpleIcon
                      class="cursor-pointer text-icon-normal tooltip-trigger"
                      :width="20"
                      :height="20"
                    />
                    <transition>
                      <div
                        class="tooltip-container text-center px-2 py-1 bg-neutral-800 rounded-md shadow-md cursor-default"
                        :style="{ 'width': '130px' }"
                      >
                        <BaseText
                          type="label"
                          size="xs"
                          class="text-white"
                        >
                         Total spend for all ads in this creative
                        </BaseText>
                      </div>
                    </transition>
                  </div>
                </div>
                <BaseText
                  type="display"
                  size="2xs"
                >
                  {{ groupAdInfo.spend.value }}
                </BaseText>
                <PercentageChangeIndicator
                  period-text="since previous period"
                  :percent="groupAdInfo.spend.previousPercent"
                />
              </div>
              <!-- Roas -->
              <div class="px-4 py-2.5 flex items-center justify-between border-b border-border-normal">
                <div class="flex items-center gap-1">
                  <BaseText
                    type="label"
                    size="sm"
                    class="text-text-normal"
                  >
                    ROAS
                  </BaseText>
                  <div
                    class="relative"
                  >
                    <InfoSimpleIcon
                      class="cursor-pointer text-icon-normal tooltip-trigger"
                      :width="20"
                      :height="20"
                    />
                    <transition>
                      <div
                        class="tooltip-container text-center px-2 py-1 bg-neutral-800 rounded-md shadow-md cursor-default"
                        :style="{'width': '130px'}"
                      >
                        <BaseText
                          type="label"
                          size="xs"
                          class="text-white"
                        >
                          ROAS across all ads in this creative
                        </BaseText>
                      </div>
                    </transition>
                  </div>
                  <BaseText
                    type="heading"
                    size="sm"
                    class="text-text-muted ml-1"
                  >
                    {{ groupAdInfo.roas.value }}
                  </BaseText>
                </div>
                <PercentageChangeIndicator
                  period-text=""
                  :percent="groupAdInfo.roas.previousPercent"
                />
              </div>
              <div class="px-4 py-2.5 flex items-center justify-between border-b border-border-normal">
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-normal"
                >
                  Started Running (Mock)
                </BaseText>
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-muted"
                >
                  Feb 12, 2024 | 184 Days
                </BaseText>
              </div>
              <div class="px-4 py-2.5 flex items-center justify-between border-border-normal">
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-normal"
                >
                  Status
                </BaseText>
                <StatusCell :data="{value: { active: groupAdInfo.status.active, inactive: groupAdInfo.status.inactive , paused: groupAdInfo.status.paused}}" />
              </div>
            </div>
            <div class="space-y-2 relative">
              <div class="coming-soon-overlay z-50 flex-col gap-1 shadow-md rounded-xl">
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-normal max-w-xs text-center"
                >
                  Custom tags, Ratings, and AI Tags
                </BaseText>
                <BaseText
                  size="sm"
                  class="text-text-subdued max-w-xs text-center"
                >
                  (Coming Soon)
                </BaseText>
              </div>
              <div class="p-1 rounded-lg bg-background-disabled space-y-1">
                <div class="p-2 flex items-center gap-1">
                  <StarredIcon class="text-icon-normal" />
                  <BaseText
                    type="label"
                    size="sm"
                    class="text-text-muted"
                  >
                    Custom Tags
                  </BaseText>
                </div>
                <div class="rounded-md py-2 px-2.5 detail-section-container bg-white flex items-center gap-1 ">
                  <div class="flex items-center gap-0.5 pl-3 p-0.5 rounded-full bg-secondary-yellow-50">
                    <BaseText
                      type="label"
                      size="sm"
                      class="text-secondary-yellow-200"
                    >
                      Agency
                    </BaseText>
                    <TagRemoveIcon class="text-secondary-yellow-200" />
                  </div>
                  <div class="flex items-center gap-0.5 pl-3 p-0.5 rounded-full bg-primary-purple-50">
                    <BaseText
                      type="label"
                      size="sm"
                      class="text-primary-purple-200"
                    >
                      UGC
                    </BaseText>
                    <TagRemoveIcon class="text-primary-purple-200" />
                  </div>
                  <BaseText
                    size="sm"
                    class="text-text-normal ml-1"
                  >
                    Add a tag...
                  </BaseText>
                </div>
              </div>
              <!-- Rating -->
              <div class="p-1 rounded-lg bg-background-disabled space-y-1">
                <div class="p-2 flex items-center gap-1">
                  <StarredIcon class="text-icon-normal" />
                  <BaseText
                    type="label"
                    size="sm"
                    class="text-text-muted"
                  >
                    Rating
                  </BaseText>
                </div>
                <div class="rounded-md py-2 px-2.5 detail-section-container bg-white">
                  <!--  -->
                  <RatingSelector :ad-rating="0" />
                </div>
              </div>
              <!-- AI Tags -->
              <div class="p-1 rounded-lg bg-background-disabled space-y-1 relative">
                <div class="p-2 flex items-center gap-1">
                  <MagicBoxIcon class="text-icon-normal" />
                  <BaseText
                    type="label"
                    size="sm"
                    class="text-text-muted"
                  >
                    AI Tags
                  </BaseText>
                </div>

                <div
                  v-for="aiType in Object.keys(adAITags)"
                  :key="aiType"
                  class="rounded-md py-2 px-2.5 detail-section-container bg-white"
                >
                  <div class="flex items-center gap-1">
                    <!--  -->
                    <AITagIcon class="text-icon-normal" />
                    <BaseText
                      type="label"
                      size="sm"
                      class="text-text-muted capitalize"
                    >
                      {{ aiType.replace('_', ' ') }}
                    </BaseText>
                  </div>

                  <div
                    v-if="adAITags[aiType].length > 0"
                    class="flex items-center gap-2 mt-2"
                  >
                    <div
                      v-for="tag in adAITags[aiType]"
                      :key="tag"
                      class="py-0.5 px-3 rounded-3xl border border-border-normal"
                    >
                      <BaseText
                        type="label"
                        size="sm"
                        class="text-text-muted"
                      >
                        {{ tag }}
                      </BaseText>
                    </div>
                  </div>
                  <div v-else>
                    N/A
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- METRICS VIEW -->
          <div
            v-if="sidebarView === SIDEVIEW.METRICS"
            class="px-4 py-2 flex flex-col gap-2.5 flex-1"
          >
            <div class="flex flex-col border-border-normal border rounded-2xl">
              <div class="flex items-center justify-between w-full p-4">
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-muted"
                >
                  Performance Metrics
                </BaseText>
                <BaseSingleDropdown
                  :trigger-class="'h-8'"
                  :selected-obj="localSelectedPreset"
                  :options="getPresets || []"
                  class="z-30"
                  min-width="130px"
                  max-width="160px"
                  :placeholder="getIsCustomPreset ? 'Custom' : 'Select a preset'"
                  empty-state-text="You have no presets"
                  :option-label="(opt) => opt.name"
                  :selected-label="(opt) => `${getIsCustomPreset ? 'Custom' : opt?.name}`"
                  @change="localSelectedPreset = $event"
                >
                  <template #icon>
                    <PresetIcon class="text-icon-normal flex-shrink-0" />
                  </template>
                </BaseSingleDropdown>
              </div>
              <div class="px-4 mb-2.5">
                <div
                  v-for="kpi in kpiList"
                  :key="kpi"
                  class="flex items-center justify-between py-1.5"
                >
                  <!--  -->
                  <BaseText
                    size="sm"
                    class="capitalize text-text-normal"
                  >
                    {{ (kpi.replaceAll('_', ' ')) }}
                  </BaseText>
                  <div
                    class="p-1 rounded-md bg-background-normal"
                    :style="[
                      adGroupData?.[kpi] !== 0 ? {backgroundColor: getColoredCell(kpi).scoreColor} : null,
                    ]"
                  >
                    <TableCell
                      :cell-value="adGroupData?.[kpi]"
                      :cell-type="getMetricLookup?.[kpi]?.type"
                      :color-format="getColoredCell(kpi).direction"
                      :metric-direction="getMetricLookup?.[kpi]?.metric_direction"
                      :hide-tooltip="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- TRANSCRIPT VIEW -->

          <!-- COMMENTS VIEW -->
          <div
            v-if="sidebarView === 'comments'"
            class="flex-grow w-full min-h-0"
          >
            <Comments
              v-if="currentAd"
              :can-modify-board="canModifyBoard && !discovery"
              :advertisement="currentAd"
              :comments.sync="comments"
              :comments-loading="commentsLoading"
            />
          </div>

          <!-- Ad Action Buttons -->
          <div
            class="w-full flex items-center py-4 pl-4 border-t border-border-normal"
            :class="advertisementActions.length > 2 ? 'gap-3' : 'gap-5'"
          >
            <!-- Report Button (TODO: Implement and add conditionals) -->
            <button
              v-if="advertisementActions.includes('report')"
              class="group flex items-center gap-1.5 px-2 py-1.5 rounded-md transition-colors hover:bg-neutral-25"
              @click="showReportModal = true"
            >
              <WarningIcon class="text-icon-normal transition-colors group-hover:text-icon-muted" />
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted transition-colors group-hover:text-black"
              >
                Report
              </BaseText>
            </button>
            <!-- Alternate download (thumbnail / collection) -->
            <button
              v-if="advertisementActions.includes('download-alt')"
              :disabled="loadingAltDownload"
              class="group flex items-center gap-1.5 px-2 py-1.5 rounded-md transition"
              :class="loadingAltDownload ? 'opacity-60 cursor-default' : 'opacity-100 hover:bg-neutral-25'"
              @click="() => {downloadAdvertisementAsset(true)}"
            >
              <!-- Loading icon logic -->
              <BaseProgressIndicatorRing
                v-show="loadingAltDownload && retrievingDownloadData"
                ref="progress-ring-alt"
                class="text-icon-normal"
                :radius="10"
              />
              <BaseLoadingSpinnerCircle
                v-if="loadingAltDownload && !retrievingDownloadData"
                class="text-icon-normal"
              />
              <DownloadIcon
                v-if="!loadingAltDownload"
                class="text-icon-normal transition-colors group-hover:text-icon-muted"
              />
              <!-- Alt download button text -->
              <BaseText
                type="label"
                size="sm"
                class="text-text-muted transition-colors "
                :class="{'group-hover:text-black': !loadingAltDownload}"
              >
                {{ getAltDownloadLabel() }}
              </BaseText>
            </button>
            <!-- Primary asset download -->
            <div
              v-if="advertisementActions.includes('download')"
              class="relative"
            >
              <button
                :disabled="loadingDownload"
                class="group flex items-center gap-1.5 px-2 py-1.5 rounded-md transition-colors"
                :class="[loadingDownload ? 'opacity-60 cursor-default' : 'opacity-100 hover:bg-neutral-25', {'bg-neutral-25': showDownloadDropdown}]"
                @click="() => {handlePrimaryAction()}"
              >
                <!-- Loading icon logic -->
                <BaseProgressIndicatorRing
                  v-show="loadingDownload && retrievingDownloadData"
                  ref="progress-ring"
                  class="text-icon-normal"
                  :radius="10"
                />
                <BaseLoadingSpinnerCircle
                  v-if="loadingDownload && !retrievingDownloadData"
                  class="text-icon-normal"
                />
                <DownloadIcon
                  v-if="!loadingDownload"
                  class="text-icon-normal transition-colors group-hover:text-icon-muted"
                />
                <!-- Download button text -->
                <BaseText
                  type="label"
                  size="sm"
                  class="text-text-muted transition-colors capitalize"
                  :class="{'group-hover:text-black': !loadingDownload}"
                >
                  {{ getDownloadLabel() }}
                </BaseText>
              </button>
              <!-- Extra options dropdown (if BaseCarousel with video thumbnail) -->
              <transition>
                <div
                  v-if="showDownloadDropdown"
                  v-on-clickaway="() => {showDownloadDropdown = false}"
                  class="download-dropdown flex flex-col gap-0.5 p-1 bg-white"
                >
                  <button
                    class="group flex items-center gap-1.5 px-2 py-1.5 rounded-md transition-colors hover:bg-neutral-25"
                    @click="handleDropdownSelection('thumbnail')"
                  >
                    <ImageFormatIcon class="text-icon-normal transition-colors group-hover:text-icon-muted" />
                    <BaseText
                      type="label"
                      size="sm"
                      class="text-text-normal transition-colors group-hover:text-text-muted"
                    >
                      Thumbnail
                    </BaseText>
                  </button>
                  <button
                    class="group flex items-center gap-1.5 px-2 py-1.5 rounded-md transition-colors hover:bg-neutral-25"
                    @click="handleDropdownSelection('video')"
                  >
                    <VideoFormatIcon class="text-icon-normal transition-colors group-hover:text-icon-muted" />
                    <BaseText
                      type="label"
                      size="sm"
                      class="text-text-normal transition-colors group-hover:text-text-muted"
                    >
                      Video
                    </BaseText>
                  </button>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <!-- Hidden video element for loading metadata -->
        <video
          v-if="['video', 'dco'].includes(currentAd?.type) && (currentAd?.video || currentAd?.cards?.[0]?.video)"
          ref="video"
          style="display: none;"
          preload="metadata"
          @loadedmetadata="onVideoMetadataLoaded"
        >
          <source
            :src="currentAd.video"
            type="video/mp4"
          >
        </video>
      </div>
      <ReportAdModal
        v-if="showReportModal"
        :advertisement="currentAd"
        @close="showReportModal = false"
      />
    </template>
  </BaseDrawer>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapMutations } from 'vuex'
import detectAdBlock from '../../../utils/adblock'
import { sendEventSocket } from '../../../api/sockets'
import JSZip from 'jszip'
import { mixin as clickaway } from 'vue-clickaway2'
import formatLabelString from '../../../utils/lens/formatGraphLabelString'
import { getRelativeMetricScoreColor } from '../../../utils/lens/metricScoreUtil'
import { getMatchingPresetName, formatCustomDateRangeLabel } from '../../../utils/dateUtils'

// Components
import Tags from '../../advertisements/DetailsDrawerComponents/Tags'
import RatingSelector from '../../advertisements/DetailsDrawerComponents/RatingSelector'
import AdDetails from '../../advertisements/DetailsDrawerComponents/AdDetails.vue'
import AIAnalysis from '../../advertisements/DetailsDrawerComponents/AIAnalysis.vue'
import Transcript from '../../advertisements/DetailsDrawerComponents/Transcript.vue'
import Comments from '../../advertisements/DetailsDrawerComponents/Comments'
import Screenshots from '../../advertisements/DetailsDrawerComponents/Screenshots'
import LibraryCard from '../../library/LibraryCard.vue'
import DiscoverySave from '../../advertisements/DetailsDrawerComponents/DiscoverySave.vue'
import ReportAdModal from '../../advertisements/DetailsDrawerComponents/ReportAdModal.vue'
import PercentageChangeIndicator from '../PercentageChangeIndicator.vue'
import TableCell from '../table/cells/TableCell.vue'
import PresetIcon from '../../globals/Icons/LensIcons/PresetIcon.vue'
// Icons
import OpenExternalIcon from '../../globals/Icons/OpenExternalIcon.vue'
import ShareIcon from '../../globals/Icons/ShareIcon.vue'
import InfoResponseIcon from '../../globals/Icons/ResponseAlertIcons/InfoResponseIcon.vue'
import DeleteV2Icon from '../../globals/Icons/DeleteV2Icon.vue'
import DownloadIcon from '../../globals/Icons/DownloadIcon.vue'
import WarningIcon from '../../globals/Icons/WarningIcon.vue'
import VideoFormatIcon from '../../globals/Icons/FilterIcons/VideoFormatIcon.vue'
import ImageFormatIcon from '../../globals/Icons/FilterIcons/ImageFormatIcon.vue'
import InfoSimpleIcon from '../../globals/Icons/InfoSimpleIcon.vue'
import StatusCell from '../table/cells/StatusCell.vue'
import CalendarIcon from '../../globals/Icons/CalendarIcon.vue'
import StarredIcon from '../../globals/Icons/StarredIcon.vue'
import MagicBoxIcon from '../../globals/Icons/LensIcons/MetricIcons/MagicBoxIcon.vue'
import AITagIcon from '../../globals/Icons/AITagIcon.vue'
import ChevronIcon from '../../globals/Icons/ChevronIcon.vue'
import TagRemoveIcon from '../../globals/Icons/TagRemoveIcon.vue'
export default {
  name: 'AdInsightsDrawer',
  components: {
    AITagIcon,
    TagRemoveIcon,
    StarredIcon,
    CalendarIcon,
    ChevronIcon,
    AdDetails,
    AIAnalysis,
    Transcript,
    MagicBoxIcon,
    RatingSelector,
    Comments,
    DiscoverySave,
    ReportAdModal,
    LibraryCard,
    OpenExternalIcon,
    InfoSimpleIcon,
    PercentageChangeIndicator,
    ShareIcon,
    InfoResponseIcon,
    PresetIcon,
    DeleteV2Icon,
    DownloadIcon,
    TableCell,
    WarningIcon,
    VideoFormatIcon,
    ImageFormatIcon,
    StatusCell
  },
  mixins: [clickaway],
  props: {
    allAds: {
      type: Array,
      default: () => []
    },
    brand: {
      type: Object,
      default: () => {}
    },
    adGroupData: {
      type: Object,
      default: () => {}
    },
    summaryStats: {
      type: Object,
      default: () => {}
    },
    dateRange: {
      type: Object,
      default: () => {}
    },
    sharedBoard: {
      type: Boolean,
      default: () => false
    },
    sharedBrief: {
      type: Boolean,
      default: () => false
    },
    discovery: {
      type: Boolean,
      default: () => false
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    adCardSavedId: {
      type: String,
      default: ''
    },
    allAdsLoaded: {
      type: Boolean,
      default: () => false
    },
    withAdCopyExpanded: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    const tabNavigations = [
      {
        name: 'Details',
        view: 'details',
        isNew: false,
        isDisabled: false
      },
      {
        name: 'Metrics',
        view: 'metrics',
        isNew: false,
        isDisabled: false
      },
      {
        name: 'Transcript',
        view: 'transcript',
        isNew: false,
        isDisabled: true
      },
      {
        name: 'Comments',
        view: 'comments',
        isNew: false,
        isDisabled: true
      }
    ]
    const SIDEVIEW = {
      DETAILS: 'details',
      METRICS: 'metrics'
    }

    // mock data TODO @Sam: remove later
    const adAITags = {
      themes: ['Testimonial', 'Demonstration', 'Us vs Them'],
      angles: ['Quality', 'H', 'Us vs Them'],
      funnel_state: ['Awareness']
    }
    return {
      // Static
      tabNavigations,
      SIDEVIEW,
      adAITags,

      localSelectedPreset: null,

      // Data States
      currentAd: {},
      advertisement: {},
      previousAd: {},
      nextAd: {},
      selectedBoards: [],
      selectedRating: [],
      selectedTags: [],
      createdBy: {},
      niches: [],
      betaUser: false,
      assetMetadata: {},
      comments: [],
      carouselIndex: 0,

      // UI States
      sidebarView: 'details',
      loadingNewAds: false,
      loadingTranscription: false,
      adBlockedDetected: false,
      loadingDelete: false,
      commentsLoading: false,
      enableAdCardScroll: false,
      showReportModal: false,
      showDownloadDropdown: false,
      noCommentsImgSrc: require('../../../assets/images/break-the-silence.png'),
      mobileView: window.innerWidth < 1200,

      // Download States
      loadingDownload: false,
      loadingAltDownload: false,
      retrievingDownloadData: false
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser']),
    ...mapGetters('MiscModule', ['getTheme']),
    ...mapGetters('LensModule', ['getPresets', 'getIsCustomPreset', 'getAppliedPreset', 'getColorFormat']),
    ...mapGetters('MetricsModule', ['getMetricLookup']),
    kpiList () {
      return this.localSelectedPreset?.table_columns ?? this.getAppliedPreset.table_columns
    },
    getInstagramUrl () {
      return `https://www.instagram.com/p/${this.currentAd?.instagramAttributes?.shortCode}`
    },
    advertisementActions () {
      // The list of action buttons to render at the bottom of the info card
      const actions = []
      if (
        (this.currentAd?.type === 'video' && this.currentAd.thumbnail) ||
        (['carousel', 'dco'].includes(this.currentAd?.type) && this.currentAd?.cards?.length)
      ) {
        actions.push('download-alt')
      }
      if (
        (this.currentAd?.type === 'video' && this.currentAd.video) ||
        (this.currentAd?.type === 'image' && this.currentAd.image) ||
        (this.currentAd?.type === 'carousel' && this.currentAd?.cards?.length) ||
        (this.currentAd?.type === 'dco' && this.currentAd?.cards?.length)
      ) {
        actions.push('download')
      }
      return actions
    },
    canModifyBoard () {
      return !this.sharedBoard && !this.sharedBrief
    },
    getShareLink () {
      let path = this.$route.path
      let pathName
      path = path.split('/')
      if (path[1] === 'library') {
        pathName = 'Swipe FIles'
      } else if (path[1] === 'boards') {
        pathName = 'Board'
      } else if (path[1] === 'discovery') {
        pathName = 'Discovery'
      }
      return (
        process.env.VUE_APP_BASE_URL +
        '/share/ads/' +
        encodeURIComponent(this.currentAd.id) +
        '?user=' +
        encodeURIComponent(this.getUser.user_id)
      )
    },
    shareDisabled () {
      // TODO @Sam: implement later
      return true 
    },
    onLastAd () {
      return this.allAdsLoaded && this.allAds.indexOf(this.currentAd) === this.allAds.length - 1
    },
    nextAdDisabled () {
      return this.onLastAd || ((this.loadingNewAds || this.loading) && !this.nextAd)
    },
    aiAnalysisEnabled () {
      return this.currentAd?.name !== 'Manual Upload' &&
        ['image', 'video', 'carousel', 'dco'].includes(this.currentAd?.type)
    },
    transcriptEnabled () {
      return this.currentAd?.type === 'video' ||
        (this.currentAd?.type === 'dco' && this.currentAd?.cards?.length && this.currentAd.cards[this.carouselIndex].video)
    },
    commentsEnabled () {
      return !this.discovery && !this.mobileView
    },
    groupAdInfo () {
      const groupData = this.adGroupData

      const prevSpend = groupData.previous_period.spend
      const spendChangeSince = ((groupData.spend - prevSpend) / prevSpend) * 100

      const prevRoas = groupData.previous_period.roas
      const roasChangeSince = ((groupData.roas - prevRoas) / prevRoas) * 100
      return {
        spend: {
          value: formatLabelString(groupData.spend, 'currency'),
          previousPercent: spendChangeSince || 0
        },
        roas: {
          value: formatLabelString(groupData.roas, 'number'),
          previousPercent: roasChangeSince || 0
        },
        status: {
          active: groupData.active_ad_count,
          paused: groupData.paused_ad_count,
          inactive: groupData.inactive_ad_count
        }
      }
    },
    dateRangeLabel () {
      const currentRange = this.dateRange
      if (!currentRange?.start || !currentRange?.end) {
        return { rangeName: 'All Time', range: null }
      } else if (currentRange.isRelativeToToday) {
        return { rangeName: getMatchingPresetName(currentRange), range: formatCustomDateRangeLabel(currentRange.start, currentRange.end) }
      } else {
        return { rangeName: null, range: formatCustomDateRangeLabel(currentRange.start, currentRange.end) }
      }
    }
  },
  watch: {
    allAds () {
      this.loadingNewAds = false
    },
    allAdsLoaded () {
      this.loadingNewAds = false
    },
    carouselIndex () {
      const card = this.$refs['advertisement-card']
      if (card) {
        card.setCarouselIndex(this.carouselIndex)
      }
      if (this.sidebarView === 'transcript' && !this.transcriptEnabled) {
        this.sidebarView = 'details'
      }
      this.$nextTick(() => { this.determineAdCardOverflow() })
    },
    currentAd: {
      async handler (newValue, oldValue) {
        // When navigating through ads, we may need to update the sidebar view
        if (
          (this.sidebarView === 'transcript' && !this.transcriptEnabled) ||
          (this.sidebarView === 'comments' && !this.commentsEnabled) ||
          (this.sidebarView === 'ai-analysis' && !this.aiAnalysisEnabled)
        ) {
          this.sidebarView = 'details'
        }

        this.carouselIndex = 0

        // Fetch the comments for the advertisement
        if (this.commentsEnabled) {
          this.fetchAdComments()
        }

        // Extract metadata from ad asset
        if (this.currentAd?.type === 'image' && this.currentAd.image) {
          const image = new Image()
          image.src = this.currentAd.image
          if (image.complete) { // In case the image was previously cached
            this.assetMetadata = { width: image.width, height: image.height }
          } else { // Otherwise, wait for the image to load
            image.onload = () => {
              this.assetMetadata = { width: image.width, height: image.height }
            }
          }
        } else if (
          (this.currentAd?.type === 'video' && this.currentAd.video) ||
          (this.currentAd?.type === 'dco' && this.currentAd?.cards?.length && this.currentAd.cards[0].video)
        ) {
          this.$nextTick(() => {
            const video = this.$refs.video
            video.pause()
            video.src = ''
            video.src = this.currentAd.video || this.currentAd.cards[0].video
            video.load()
            // The metadata computations are perfomed when the <video> element emits the 'loadedmetadata' event
          })
        }

        this.$nextTick(() => { this.determineAdCardOverflow() })

        if (newValue.id && newValue.id !== oldValue.id) {
          sendEventSocket('adViewed', { adId: this.currentAd.id, brandId: this.currentAd.brandId })
        }
      },
      immediate: true
    }
  },
  async mounted () {
    window.addEventListener('resize', this.handleResize)

    this.SET_AD_PLAYING('')
    const groupData = this.adGroupData

    const adCards = groupData.ads.map((ad) => {
      return {
        ...ad,
        name: this.brand.name,
        image: ad.ad_image_url,
        description: ad.ad_copy,
        link_url: ad.destination_url,
        avatar: this.brand?.avatar,
        cta_text: ad.creative_cta_type,
        video: ad.video_url_source,
        type: ad.ad_type
      }
    })
    this.currentAd = adCards[0]
    console.log('Mapped current ad:', this.currentAd)
    this.localSelectedPreset = this.getIsCustomPreset ? null : (this.getAppliedPreset ?? null)
    this.previousAd = this.allAds[(this.allAds.indexOf(this.advertisement) - 1)]
    this.nextAd = this.allAds[(this.allAds.indexOf(this.advertisement) + 1)]
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    ...mapMutations('AuthModule', ['SET_USER']),
    ...mapMutations('AdvertisementsModule', ['SET_AD_PLAYING']),
    ...mapMutations('MiscModule', ['SET_PREVENT_DRAWER_ESCAPE_KEY']),
    detectAdBlock,

    // ================================================================================
    // ================================= DATA METHODS =================================
    // ================================================================================
    showColoredCell: _.memoize(function (percentDiff, kpi, outlierFormat) {
      return getRelativeMetricScoreColor(percentDiff, kpi, outlierFormat, this.summaryStats)
    }, (arg1, arg2, arg3) => `${arg1}-${arg2}-${arg3}`),
    getColoredCell (kpi) {
      const percentDiff = Math.floor(this.adGroupData.percent_deviation?.[kpi])
      return this.showColoredCell(percentDiff, kpi, this.getColorFormat)
    },
    formatValue (value, type) {
      if (!value && value !== 0) return '-'

      if (type === 'currency') {
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        }).format(value)
      } else if (type === 'percent') {
        return `${value?.toFixed(2)}%`
      } else {
        return new Intl.NumberFormat('en-US', {
          style: 'decimal',
          maximumFractionDigits: 2
        }).format(value)
      }
    },
    async handleUpdateAdRating (rating) {

    },
    handleUpdateAdTags: _.debounce(async function () {

    }, 1000),
    handleUpdateAdBoardsLocal (boardIds) {
      const copyAd = { ...this.currentAd, board_ids: boardIds }
      this.$emit('updateAds', [copyAd])
      this.currentAd = copyAd
    },
    async nextAdvertisement () {
    },
    async previousAdvertisement () {
    },
    async getCreatedByUser () {
    },
    async getNiches () {
    },
    async fetchAdComments () {
    },

    // ================== DOWNLOAD METHODS ==================

    handlePrimaryAction () {
      if (
        ['carousel', 'dco', 'dpa'].includes(this.currentAd?.type) &&
        this.currentAd?.cards[this.carouselIndex].thumbnail
      ) {
        this.showDownloadDropdown = !this.showDownloadDropdown
      } else {
        this.downloadAdvertisementAsset()
      }
    },
    handleDropdownSelection (type) {
      const animateProgress = this.$refs['progress-ring'].updateProgress
      const filename = `${this.currentAd.type}-${this.carouselIndex}-${type}-${this.currentAd.id}`
      const url = type === 'thumbnail'
        ? this.currentAd.cards[this.carouselIndex].thumbnail
        : this.currentAd.cards[this.carouselIndex].video
      this.showDownloadDropdown = false
      this.executeDownload(url, filename, animateProgress).catch((error) => {
        console.log('Asset fetch request was rejected', error)
        this.executeBackupDownload(url, error)
      })
    },
    async downloadAdvertisementAsset (alternate = false) {
      // Reset progress states and flag loading
      this.targetDownloadProgress = 0
      this.renderedDownloadProgress = 0
      alternate ? this.loadingAltDownload = true : this.loadingDownload = true

      // Get the progress animation function
      const refStr = alternate ? 'progress-ring-alt' : 'progress-ring'
      const animateProgress = this.$refs[refStr].updateProgress

      // Configure the download based on the advertisement and type
      let url = null
      let filename = null
      console.log(this.currentAd.type)
      try {
        if (this.currentAd?.type === 'image') {
          filename = `image-${this.currentAd.id}`
          url = this.currentAd.image
        } else if (this.currentAd?.type === 'video') {
          filename = `${alternate ? 'thumbnail' : 'video'}-${this.currentAd.id}`
          url = alternate ? this.currentAd.thumbnail : this.currentAd.video
          console.log('sam', url)
        } else if (this.currentAd?.type === 'carousel') {
          if (alternate) {
            filename = `carousel-${this.currentAd.id}`
            await this.executeCollectionDownload(this.currentAd.cards, filename, animateProgress)
            return
          }
          filename = `carousel-${this.carouselIndex}-${this.currentAd.id}`
          url = this.currentAd.cards[this.carouselIndex].image ||
            this.currentAd.cards[this.carouselIndex].video
        } else if (this.currentAd?.type === 'dco') {
          if (alternate) {
            filename = `dco-${this.currentAd.id}`
            await this.executeCollectionDownload(this.currentAd.cards, filename, animateProgress)
            return
          }
          filename = `dco-${this.carouselIndex}-${this.currentAd.id}`
          url = this.currentAd.cards[this.carouselIndex].image ||
            this.currentAd.cards[this.carouselIndex].video
        }
        await this.executeDownload(url, filename, animateProgress)
      } catch (error) {
        console.error(error)
        this.executeBackupDownload(url, error)
      } finally {
        alternate ? this.loadingAltDownload = false : this.loadingDownload = false
      }
    },
    async executeDownload (url, filename, animateProgress) {
      // Fetch the asset
      const response = await nativeFetch(url, { mode: 'cors' })
      console.log(response)
      // Get the content length to track download progress
      const contentLength = response.headers.get('Content-Length')
      if (!response.body || !contentLength) {
        throw new Error('Readable steams or content length not supported')
      }

      this.retrievingDownloadData = true

      // Create a readable stream
      const totalBytes = parseInt(contentLength, 10)
      let loadedBytes = 0
      const reader = response.body.getReader()
      const stream = new ReadableStream({
        start: async (controller) => {
          while (true) {
            const { done, value } = await reader.read()
            if (done) break

            loadedBytes += value.byteLength
            animateProgress(loadedBytes / totalBytes)

            controller.enqueue(value)
          }
          controller.close()
        }
      })

      const blob = await new Response(stream).blob()
      this.retrievingDownloadData = false

      // Create a downloadable URL for the fetched asset
      const downloadUrl = URL.createObjectURL(blob)
      const fileExtension = url.split('.').pop()
      const fullFilename = `${filename}.${fileExtension}`
      this.triggerDownload(downloadUrl, fullFilename)
    },
    async executeCollectionDownload (assets, filename, animateProgress) {
      // Assets are expected to be an object array with image or video keys

      // Prepare the jszip instance and progress variables
      const zip = new JSZip()
      let totalBytes = 0
      let loadedBytes = 0

      // First we fetch the content length for each asset to track download progress
      const assetSizes = await Promise.all(
        assets.map(async (asset) => {
          const url = asset.image || asset.video
          const response = await nativeFetch(url, { method: 'HEAD', mode: 'cors' })
          return parseInt(response.headers.get('Content-Length'), 10)
        })
      )
      totalBytes = assetSizes.reduce((acc, size) => acc + size, 0)

      this.retrievingDownloadData = true

      // Fetch and add each asset to the zip file
      await Promise.all(
        assets.map(async (asset, index) => {
          const url = asset.image || asset.video
          const response = await nativeFetch(url, { mode: 'cors' })
          if (!response.body) {
            throw new Error('Readable steams not supported')
          }

          const reader = response.body.getReader()
          const fileExtension = url.split('.').pop()
          const fullFilename = `${index}-${filename}.${fileExtension}`
          const fileData = []

          // Create a readable stream
          const stream = new ReadableStream({
            async start (controller) {
              while (true) {
                const { done, value } = await reader.read()
                if (done) break

                fileData.push(value)
                loadedBytes += value.byteLength
                animateProgress(loadedBytes / totalBytes)
                controller.enqueue(value)
              }
              controller.close()
            }
          })

          const blob = await new Response(stream).blob()
          const arrayBuffer = await blob.arrayBuffer()
          zip.file(fullFilename, arrayBuffer)
        })
      )
      this.retrievingDownloadData = false

      // Generate the zip file and download it
      const zipBlob = await zip.generateAsync({ type: 'blob' })
      const zipUrl = URL.createObjectURL(zipBlob)
      this.triggerDownload(zipUrl, `${filename}.zip`)
    },
    async executeBackupDownload (url, error) {
      console.log('Attempting backup download')

      if (!url) {
        console.error('Download failed:', error)
        this.$showAlert({
          message: 'Failed to download asset',
          type: 'error'
        })
        return
      }

      // Open the download url in a new tab
      window.open(url, '_blank')
    },
    triggerDownload (url, downloadFilename) {
      // Create a hidden anchor element to trigger the download
      const anchor = document.createElement('a')
      anchor.href = url
      anchor.download = downloadFilename
      anchor.addEventListener('click', (event) => { event.stopPropagation() })
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)

      // Revoke the URL to free up memory
      URL.revokeObjectURL(url)
    },

    // ================================================================================
    // ================================== UI METHODS ==================================
    // ================================================================================

    showIntercom () {
      window.Intercom('show')
    },
    determineAdCardOverflow () {
      if (this.mobileView) {
        this.enableAdCardScroll = true
        return
      }
      this.$nextTick(() => {
        requestAnimationFrame(() => {
          const cardArea = this.$refs['advertisement-card-area']
          const card = this.$refs['advertisement-card'].$el
          if (cardArea && card) {
            // Subtract 48px for padding and 24px for vert center offset
            const scrollThreshold = cardArea.getBoundingClientRect().height - 48 - 24
            const cardHeight = card.getBoundingClientRect().height
            this.enableAdCardScroll = cardHeight > scrollThreshold
          }
        })
      })
    },

    getDownloadLabel () {
      if (!this.currentAd.type) return 'Download'
      switch (this.currentAd.type) {
        case 'carousel': return 'Current Card'
        case 'dco': return 'Current Variant'
        default: return this.currentAd.type
      }
    },
    getAltDownloadLabel () {
      if (!this.currentAd.type) return 'Alt'
      switch (this.currentAd.type) {
        case 'video': return 'Thumbnail'
        default: return 'Collection'
      }
    },

    // ================================================================================
    // ================================ EVENT HANDLERS ================================
    // ================================================================================

    handleResize: _.debounce(function () {
      this.mobileView = window.innerWidth < 1200
    }, 100),
    // Handle Ad Share Link Copy
    handleClipboardCopy () {
      this.$showAlert({
        message: 'Copied share link to clipboard!',
        type: 'success'
      })
    },
    playAdVideoAtTimestamp (time) {
      const adCard = this.$refs['advertisement-card']
      if (!adCard) return
      adCard.playVideoAtTimestamp(time)
    },
    onVideoMetadataLoaded () {
      const video = this.$refs.video
      const { duration, videoWidth, videoHeight } = video
      this.assetMetadata = { duration, width: videoWidth, height: videoHeight }
      video.pause()
      video.src = ''
      video.load() // Reinitializing load without source will stop any ongoing download
    },
    handleBoardsDropdownOpened (isOpen) {
      this.SET_PREVENT_DRAWER_ESCAPE_KEY(isOpen)
    },
    // Handle Delete Advertisement
    async handleDeleteAdvertisement () {
    },
    forwardTagEdits (tagId, updatedTag) {
      this.$emit('update:tableViewTags', tagId, updatedTag)
    }
  }
}
</script>

<style scoped>
.detail-section-container{
  box-shadow: 0px 0px 0.75px 0.75px #06070F0A,
              0px 1.5px 1.5px -0.75px #06070F0A,
              0px 3px 3px -1.5px #06070F0A;
}

@property --share-btn-opacity-1 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}
@property --share-btn-opacity-2 {
  syntax: '<number>';
  initial-value: 0.12;
  inherits: false;
}

.info-card {
  box-shadow: 0px 3px 3px -1.5px rgba(6, 7, 16, 0.04), 0px 1.5px 1.5px -0.75px rgba(6, 7, 16, 0.06), 0px 0px 0.25px 0.75px rgba(6, 7, 16, 0.06);
}
.actions-shadow {
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.09), 0px 0px 0px 1px rgba(0, 56, 108, 0.04);
}
.center-toolbar-actions {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.center-toolbar-actions .action-btn:disabled {
  cursor: default;
  background-color: #F6F8FA;
}
.action-btn.enabled {
  border: 1px solid rgba(255, 255, 255, 0.12);
  background: linear-gradient(180deg, rgba(255, 255, 255, var(--share-btn-opacity-1)) 0%, rgba(255, 255, 255, var(--share-btn-opacity-2)) 100%), #006BFF;
  box-shadow: 0px -1px 12px 0px rgba(255, 255, 255, 0.12) inset, 0px 0px 0px 1px #0063F4;
  transition: --share-btn-opacity-1 150ms ease-in-out, --share-btn-opacity-2 150ms ease-in-out;
  transform: scale(0.95);
  transform-origin: center;
}
.action-btn.enabled:hover {
  --share-btn-opacity-1: 0.24;
  --share-btn-opacity-2: 0;
}
.action-btn.enabled:active {
  --share-btn-opacity-1: 0;
  --share-btn-opacity-2: 0.24;
}
.centered-card-wrapper {
  position: absolute;
  top: calc(50% - 20px);
  left: 50%;
  transform: translate(-50%, -50%);
}
.details-organization-grid {
  display: grid;
  grid-template-columns: 136px auto;
  max-width: 100%;
}

.gray-background{
  background-image: url('../../../assets/images/gray-striped-background.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.icon-shadow {
  box-shadow: 0px 1px 2px rgba(9, 25, 72, 0.13), 0px 0px 0px 1px rgba(18, 55, 105, 0.08);
  border-radius: 999px;
}

.scrollable-section {
  overflow-y: auto;
}
.scrollable-section::-webkit-scrollbar {
  width: 3px;
}
.scrollable-section::-webkit-scrollbar-thumb {
  background-color: #DFE1E7;
  border-radius: 10000px;
}

.download-dropdown {
  position: absolute;
  bottom: calc(100% + 8px);
  left: 0;
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(4, 26, 75, 0.13), 0px 0px 0px 1px rgba(0, 56, 108, 0.08);
}

.coming-soon-overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-enter-active, .v-leave-active {
  transition: opacity 100ms ease-in-out;
}
.v-enter-from, .v-enter, .v-leave-to {
  opacity: 0;
}
.v-enter-to, .v-leave-from {
  opacity: 1;
}

.tooltip-container{
  pointer-events: none;
  position: absolute;
  bottom: calc(100% + 4px);
  left: 50%;
  transform: translateX(-50%) translateY(0);
  opacity: 0;
}
.tooltip-trigger:hover + .tooltip-container{
  opacity: 1
}

</style>
